import React from 'react';
import { GetPageDocument, GetPageQuery } from 'cms/queries/getPage';
import { getApolloClient, NextApolloPageContext } from 'common/hooks/useApollo';
import { BaseLayout } from 'common/components/BaseLayout';
import { Page, PageProps } from 'cms/components/Page';
import { GetServerSidePropsContext } from 'next';

const CMSPage = ({ slug }: PageProps) => {
  return <Page slug={slug} />;
};

CMSPage.layout = BaseLayout;

export async function getServerSideProps({
  res,
  params,
  ...ctx
}: GetServerSidePropsContext) {
  const apolloClient = getApolloClient(undefined, ctx as NextApolloPageContext);
  const slug = params?.slug?.[0] || 'index';
  const { data, error } = await apolloClient.query<GetPageQuery>({
    query: GetPageDocument,
    errorPolicy: 'all',
    variables: {
      slug
    }
  });

  if (!data || error) {
    return {
      notFound: true
    };
  }

  if (error) {
    return {
      error
    };
  }

  // TODO: Prevent user credential leak on CMS pages
  // if (data.page?.publishedAt) {
  //   res.setHeader(
  //     'Cache-Control',
  //     'public, s-maxage=300, stale-while-revalidate=3600'
  //   );
  // }

  return {
    props: {
      slug,
      initialApolloState: apolloClient.cache.extract()
    }
  };
}

export default CMSPage;
