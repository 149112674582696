import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetComponentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetComponentsQuery = { __typename?: 'Query', components: Array<{ __typename?: 'Component', uid: string, apiId: string, component: string, category: string, attributes: Array<{ __typename?: 'ComponentAttribute', default?: string | null | undefined, enum?: Array<string> | null | undefined, name: string, required: boolean, component?: string | null | undefined, repeatable?: boolean | null | undefined, min?: number | null | undefined, max?: number | null | undefined, type: string, allowedTypes?: Array<string> | null | undefined }> }> };


export const GetComponentsDocument = gql`
    query GetComponents {
  components {
    uid
    apiId
    component
    category
    attributes {
      default
      enum
      name
      required
      component
      repeatable
      min
      max
      type
      allowedTypes
    }
  }
}
    `;

/**
 * __useGetComponentsQuery__
 *
 * To run a query within a React component, call `useGetComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComponentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetComponentsQuery(baseOptions?: Apollo.QueryHookOptions<GetComponentsQuery, GetComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComponentsQuery, GetComponentsQueryVariables>(GetComponentsDocument, options);
      }
export function useGetComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComponentsQuery, GetComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComponentsQuery, GetComponentsQueryVariables>(GetComponentsDocument, options);
        }
export type GetComponentsQueryHookResult = ReturnType<typeof useGetComponentsQuery>;
export type GetComponentsLazyQueryHookResult = ReturnType<typeof useGetComponentsLazyQuery>;
export type GetComponentsQueryResult = Apollo.QueryResult<GetComponentsQuery, GetComponentsQueryVariables>;