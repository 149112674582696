import { FC } from 'react';
import dynamic, { LoaderComponent } from 'next/dynamic';

interface CMSComponent extends FC {
  strapiComponent: string;
}

const components = {
  'material-ui.button': dynamic(
    () =>
      import('@mui/material/Button').then(mod => mod.default) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.callout-grid': dynamic(
    () =>
      import('./CalloutGrid').then(mod => mod.CalloutGrid) as LoaderComponent<unknown>
  ) as CMSComponent,
  'maastr.callout-grid-item': dynamic(
    () =>
      import('./CalloutGrid').then(mod => mod.CalloutGridItem) as LoaderComponent<unknown>
  ) as CMSComponent,
  'maastr.callout-grid-item-list-item': dynamic(
    () =>
      import('./CalloutGrid').then(mod => mod.CalloutGridItemListItem) as LoaderComponent<unknown>
  ) as CMSComponent,
  'maastr.card-grid': dynamic(
    () =>
      import('./CardGrid').then(mod => mod.CardGrid) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.card-grid-item': dynamic(
    () =>
      import('./CardGrid').then(mod => mod.CardGridItem) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.hero': dynamic(
    () =>
      import('./Hero').then(mod => mod.Hero) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.inline-youtube-player': dynamic(
    () =>
      import('./InlineYoutubePlayer').then(mod => mod.InlineYoutubePlayer) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.logo-divider': dynamic(
    () =>
      import('./LogoDivider').then(mod => mod.LogoDivider) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.quote-carousel': dynamic(
    () =>
      import('./QuoteCarousel').then(mod => mod.QuoteCarousel) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.quote-carousel-item': dynamic(
    () =>
      import('./QuoteCarousel').then(mod => mod.QuoteCarouselItem) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.app-bar': dynamic(
    () =>
      import('./AppBar').then(mod => mod.AppBar) as LoaderComponent<unknown>) as CMSComponent,
  'head.meta': dynamic(
    () =>
      import('./Meta').then(mod => mod.Meta) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.heading': dynamic(
    () =>
      import('./Typography').then(mod => mod.Heading) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.paragraph': dynamic(
    () =>
      import('./Typography').then(mod => mod.Paragraph) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.demo-player': dynamic(
    () =>
      import('./DemoPlayer').then(mod => mod.DemoPlayer) as LoaderComponent<unknown>) as CMSComponent,
  'maastr.unauthed-checkout': dynamic(
    () =>
      import('./UnauthedCheckout').then(mod => mod.UnauthedCheckout) as LoaderComponent<unknown>) as CMSComponent,
}

const componentMap = Object.entries(components).reduce<{ [key: string]: CMSComponent }>(
  (acc, [key, component]) => {
    if (key) {
      acc[key] = component;
    }
    return acc;
  },
  {}
);

export default componentMap;
