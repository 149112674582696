import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePageMutationVariables = Types.Exact<{
  slug: Types.Scalars['String'];
  title?: Types.InputMaybe<Types.Scalars['String']>;
  backgroundImage?: Types.InputMaybe<Types.Scalars['URL']>;
  backgroundOverlayImage?: Types.InputMaybe<Types.Scalars['URL']>;
  content?: Types.InputMaybe<Array<Types.Scalars['JSON']> | Types.Scalars['JSON']>;
  head?: Types.InputMaybe<Array<Types.Scalars['JSON']> | Types.Scalars['JSON']>;
  published?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UpdatePageMutation = { __typename?: 'Mutation', updatePage: { __typename?: 'Page', id: string, slug: string, title?: string | null | undefined, content: Array<Record<string, unknown>>, head: Array<Record<string, unknown>>, backgroundImage?: string | null | undefined, backgroundOverlayImage?: string | null | undefined, publishedAt?: number | null | undefined } };


export const UpdatePageDocument = gql`
    mutation UpdatePage($slug: String!, $title: String, $backgroundImage: URL, $backgroundOverlayImage: URL, $content: [JSON!], $head: [JSON!], $published: Boolean) {
  updatePage(
    input: {slug: $slug, title: $title, backgroundImage: $backgroundImage, backgroundOverlayImage: $backgroundOverlayImage, content: $content, head: $head, published: $published}
  ) {
    id
    slug
    title
    content
    head
    backgroundImage
    backgroundOverlayImage
    publishedAt
  }
}
    `;
export type UpdatePageMutationFn = Apollo.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      title: // value for 'title'
 *      backgroundImage: // value for 'backgroundImage'
 *      backgroundOverlayImage: // value for 'backgroundOverlayImage'
 *      content: // value for 'content'
 *      head: // value for 'head'
 *      published: // value for 'published'
 *   },
 * });
 */
export function useUpdatePageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, options);
      }
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;