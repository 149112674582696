import React, { useMemo } from 'react';
import { useGetPageQuery } from 'cms/queries/getPage';
import components from 'cms/components';
import { Box } from '@mui/material';
import { theme } from 'common/components/ThemeProvider';
import { PageEditor } from './PageEditor';
import { usePageState } from 'cms/hooks/usePageState';
import Head from 'next/head';
import { AuthRole } from 'common/types/graphql-types';
import { useMeQuery } from 'user/queries/me';

export interface PageProps {
  slug: string;
}
const mask =
  'linear-gradient(to bottom, rgba(255,255,255,1) 50%,rgba(255,255,255,0) 95%)';

export const Page = ({ slug }: PageProps) => {
  const { data } = useGetPageQuery({
    variables: { slug },
    fetchPolicy: 'cache-only'
  });
  const { data: authData } = useMeQuery();
  const [pageState] = usePageState();
  const sourceData = useMemo(() => pageState || data?.page, [pageState, data]);

  if (!sourceData) {
    return null;
  }

  return (
    <>
      <Head>
        <title>{sourceData.title}</title>
        {sourceData.head.map(({ id, component, ...headProps }, i: number) => {
          const [, Component] = (component as string).split('.');
          return <Component key={i} {...headProps} />;
        })}
      </Head>
      <Box
        paddingX={2}
        minHeight={'100%'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        sx={{
          '&:before': {
            ...theme.mixins.makeBackground(
              sourceData?.backgroundImage,
              undefined,
              false
            ),
            display: 'block',
            content: '" "',
            width: '100%',
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            top: 0,
            left: 0,
            opacity: 0.2,
            mask,
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
            [theme.breakpoints.up('lg')]: {
              backgroundSize: '100vw'
            }
          },
          '&:after': {
            display: 'block',
            position: 'absolute',
            pointerEvents: 'none',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            mask,
            backdropFilter: 'brightness(0.75)'
          },
          ...theme.mixins.makeBackground(sourceData?.backgroundOverlayImage),
          backgroundPosition: 'center calc(-36.66vw + 800px)',
          minHeight: '100vh',
          backgroundSize: '100vw',
          backgroundRepeat: 'no-repeat',
          [theme.breakpoints.only('xs')]: {
            backgroundImage: 'none'
          }
        }}
      >
        {sourceData.content.map(({ component, ...contentProps }, i: number) => {
          const Component = components?.[component as string];
          if (!Component) {
            return null;
          }
          return <Component key={i} {...contentProps} />;
        })}
      </Box>
      {authData?.me && authData?.me?.roles.indexOf(AuthRole.Admin) >= 0 && (
        <PageEditor slug={slug} />
      )}
    </>
  );
};
