import { Payload, useGlobalState } from 'common/hooks/useGlobalState';
import {
  START_EDITING,
  EDIT_COMPONENT,
  STOP_EDITING,
  PageState,
  REMOVE_COMPONENT,
  MOVE_COMPONENT
} from 'cms/store/pageStore';
import { Component } from 'common/types/graphql-types';
import * as uuid from 'uuid';

export interface UsePageStateMethods {
  startEditing: (content: PageState) => void;
  editComponent: (path: string, component: string | boolean | number) => void;
  addComponent: (path: string, value: Component) => void;
  removeComponent: (path: string) => void;
  moveComponent: (path: string, start: number, end: number) => void;
  stopEditing: () => void;
}

const parseValue = (value: unknown) => {
  switch (true) {
    case value === 'true':
      return true;
    case value === 'false':
      return false;
    case !isNaN(parseFloat(value as string)):
      return parseFloat(value as string);
    default:
      return value;
  }
};

export const usePageState = (): [PageState, UsePageStateMethods] => {
  const { state, dispatch } = useGlobalState();
  const startEditing = (content: PageState) => {
    dispatch({
      type: START_EDITING,
      payload: content as Payload
    });
    return;
  };

  const editComponent = (path: string, value: string | boolean | number) => {
    dispatch({
      type: EDIT_COMPONENT,
      payload: { path, value }
    });
  };

  const addComponent = (path: string, value: Component) => {
    const attributes = value.attributes.reduce<Record<string, unknown>>(
      (acc, attr) => {
        acc[attr.name] = attr.repeatable ? [] : parseValue(attr.default);
        return acc;
      },
      {}
    );

    attributes.localId = uuid.v4();
    attributes.component = value.uid;

    dispatch({
      type: EDIT_COMPONENT,
      payload: { path, value: attributes }
    });
  };

  const moveComponent = (path: string, start: number, end: number) => {
    dispatch({
      type: MOVE_COMPONENT,
      payload: { path, start, end }
    });
  };

  const removeComponent = (path: string) => {
    dispatch({
      type: REMOVE_COMPONENT,
      payload: { path }
    });
  };

  const stopEditing = () => {
    dispatch({
      type: STOP_EDITING
    });
    return;
  };
  return [
    state?.page || null,
    {
      startEditing,
      editComponent,
      addComponent,
      stopEditing,
      moveComponent,
      removeComponent
    }
  ];
};
