import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField
} from '@mui/material';
import { useCreatePageMutation } from 'cms/mutations/createPage';
import { useGraphqlForm } from 'common/hooks/useGraphqlForm';
import { BaseSyntheticEvent, FC, MouseEvent, useCallback } from 'react';

type Inputs = {
  slug: string;
};

const defaultValues: Inputs = { slug: '' };

const CreatePageModal: FC<DialogProps> = ({ onClose, ...props }) => {
  const [createPage, { error, loading }] = useCreatePageMutation();
  const { register, handleSubmit, errors } = useGraphqlForm<Inputs>(error, {
    defaultValues
  });
  const handleCreate = useCallback(
    async ({ slug }: Inputs, evt?: BaseSyntheticEvent<{}>) => {
      if (loading) {
        return;
      }
      if (!slug) {
        return;
      }
      await createPage({
        variables: {
          slug
        }
      });
      window.location.href = `/${slug}`;
      handleClose(evt as MouseEvent<HTMLElement>);
    },
    []
  );

  const handleClose = useCallback(
    (evt) => {
      if (onClose) {
        onClose(evt, 'backdropClick');
      }
    },
    [onClose]
  );
  return (
    <Dialog {...props} onClose={handleClose}>
      <DialogTitle>Create new page</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth={true}
          label={'Slug'}
          inputProps={{
            ...register('slug', {
              required: 'Slug is required.',
              pattern: /[a-z0-9-]+/,
              setValueAs: (value) =>
                value?.toLowerCase()?.trim()?.replace(/\s/, '-')
            })
          }}
          helperText={errors?.slug?.message}
          error={!!errors?.slug}
        />
        <DialogActions>
          <Button onClick={handleClose} variant={'outlined'}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(handleCreate)}
            variant={'contained'}
            color={'primary'}
          >
            Create
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export { CreatePageModal };
