import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPageQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
  isAdmin?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetPageQuery = { __typename?: 'Query', page: { __typename?: 'Page', id?: string, slug: string, title?: string | null | undefined, head: Array<Record<string, unknown>>, content: Array<Record<string, unknown>>, backgroundImage?: string | null | undefined, backgroundOverlayImage?: string | null | undefined, publishedAt?: number | null | undefined, allowedComponents?: Array<string> } };


export const GetPageDocument = gql`
    query GetPage($slug: String!, $isAdmin: Boolean = false) {
  page(slug: $slug) {
    id @include(if: $isAdmin)
    slug
    title
    head
    content
    backgroundImage
    backgroundOverlayImage
    publishedAt
    allowedComponents @include(if: $isAdmin)
  }
}
    `;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions: Apollo.QueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
      }
export function useGetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;