import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkFileAsPublicMutationVariables = Types.Exact<{
  filename: Types.Scalars['String'];
  purpose: Types.FileUploadPurpose;
}>;


export type MarkFileAsPublicMutation = { __typename?: 'Mutation', markFileAsPublic: { __typename?: 'PublicFile', filename: string, publicUrl: string } };


export const MarkFileAsPublicDocument = gql`
    mutation MarkFileAsPublic($filename: String!, $purpose: FileUploadPurpose!) {
  markFileAsPublic(input: {filename: $filename, purpose: $purpose}) {
    filename
    publicUrl
  }
}
    `;
export type MarkFileAsPublicMutationFn = Apollo.MutationFunction<MarkFileAsPublicMutation, MarkFileAsPublicMutationVariables>;

/**
 * __useMarkFileAsPublicMutation__
 *
 * To run a mutation, you first call `useMarkFileAsPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFileAsPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFileAsPublicMutation, { data, loading, error }] = useMarkFileAsPublicMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      purpose: // value for 'purpose'
 *   },
 * });
 */
export function useMarkFileAsPublicMutation(baseOptions?: Apollo.MutationHookOptions<MarkFileAsPublicMutation, MarkFileAsPublicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkFileAsPublicMutation, MarkFileAsPublicMutationVariables>(MarkFileAsPublicDocument, options);
      }
export type MarkFileAsPublicMutationHookResult = ReturnType<typeof useMarkFileAsPublicMutation>;
export type MarkFileAsPublicMutationResult = Apollo.MutationResult<MarkFileAsPublicMutation>;
export type MarkFileAsPublicMutationOptions = Apollo.BaseMutationOptions<MarkFileAsPublicMutation, MarkFileAsPublicMutationVariables>;